import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "pk", "cs", 'error_div', 'error_message','submit', 'spinner']

  connect() {
    const stripePrivateKey = this.pkTarget.dataset.value;
    const stripeCustomerSecret = this.csTarget.dataset.value;
    const stripe = Stripe(stripePrivateKey);
    const options = {
      clientSecret: stripeCustomerSecret
    };
    const errorDiv = this.error_divTarget;
    const errorMessage = this.error_messageTarget;
    const submit = this.submitTarget;
    const spinner = this.spinnerTarget;
    const elements = stripe.elements(options);
    const paymentElement = elements.create('payment');

    paymentElement.mount('#payment-element');
    var setupForm = document.getElementById('setup-form');
    setupForm.addEventListener('submit', function (ev) {
      submit.classList.add('hidden');
      spinner.classList.remove('hidden');
      ev.preventDefault();
      const returnUrlRails = window.location.protocol
        + '//'
        + window.location.host
        + '/fee_for_service/sessions/stripe_success'
      stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrlRails
        },
        redirect: 'always'
      }).then(function (result) {
        if (result.error) {
          console.clear();
          spinner.classList.add('hidden');
          submit.classList.remove('hidden');
          errorDiv.classList.remove('hidden');
          if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
            errorMessage.innerText = result.error.message
          }
      }})
    })
  }
}
