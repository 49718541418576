import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.getElementById('register_submit').disabled = true;
  }
  
  next_customer(event) {
    console.log("next button clicked");
    var x = (document.getElementById("cgv").checked);
    var y = (document.getElementById("confidentiality").checked);
    if (x == true && y == true) {
      document.getElementById('register_submit').disabled = false;
    }
    else {
      document.getElementById('register_submit').disabled = true;
    }
  }

  next_company(event) {
    console.log("next button clicked");
    var x = (document.getElementById("cgv").checked);
    if (x == true) {
      document.getElementById('register_submit').disabled = false;
    }
    else {
      document.getElementById('register_submit').disabled = true;
    }
  }
}