import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["output", "submit", "pk", "backLink"];

  connect = () => {
    this.prepare_card();
  };

  prepare_card = () => {
    let cardInput = document.querySelector("#card-input");

    let stripePublicKey = this.pkTarget.dataset.value;
    let stripe = Stripe(stripePublicKey);

    let elements = stripe.elements();
    let cardElement = elements.create("card", {
      style: {
        base: {
          color: "#000",
          fontWeight: 600,
          fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",

          ":focus": {
            color: "#424770",
          },

          "::placeholder": {
            color: "#9BACC8",
          },

          ":focus::placeholder": {
            color: "#CFD7DF",
          },
        },
        invalid: {
          color: "#fff",
          ":focus": {
            color: "#FA755A",
          },
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      },
    });

    cardElement.mount("#card-element");
    let setupForm = document.getElementById("setup-form");
    let clientSecret = setupForm.dataset.secret;
    setupForm.addEventListener("submit", (ev) => {
      ev.preventDefault();
      document.getElementById("card-button").disabled = true;
      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        })
        .then((result) => {
          if (result.error) {
            document.getElementById("card-button").disabled = false;
          } else {
            location.replace(
              [
                window.location.origin,
                "mooncard",
                "customer",
                this.extractIdFromUrl(),
                "edit",
              ].join("/")
            );
          }
        });
    });
  };

  extractIdFromUrl = () => {
    const url = window.location.href;
    const regex = /\/mooncard\/customer\/([a-f0-9\-]+)\/edit/;
    const match = url.match(regex);

    if (match) {
      return match[1];
    }
  };
}
