import Pickr from '@simonwep/pickr';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['icon', 'input']

  connect() {
    this.pickr = this.buildPickr();
    this.pickr.on('change', (color, instance) => {
      this.inputTarget.value = color.toHEXA();
    });
  }

  change(event) {
    this.pickr.setColor(event.target.value);
  }

  buildPickr() {
    return Pickr.create({
      el: this.iconTarget.firstChild,
      default: this.inputTarget.value || '#97C00C',
      container: 'body',
      theme: 'nano',
      autoReposition: true,
      comparison: false,
      components: {
        palette: true,
        hue: true,
      }
    });
  }  
}