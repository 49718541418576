import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['signin', 'registration']

  displayRegistration() {
    this.registrationTarget.classList.remove('hidden');
    this.signinTarget.classList.add('hidden');
  }

  displaySignIn() {
    this.registrationTarget.classList.add('hidden');
    this.signinTarget.classList.remove('hidden');
  }
}