import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['unitCounter', 'unit', 'threshold', 'duration']

  connect() {
    this.update()
  }

  focus(event) {
    this.cellTarget(event.target).classList.add('outline')
  }

  blur(event) {
    this.cellTarget(event.target).classList.remove('outline')
  }

  changeUnit(event) {
    this.update()
  }

  update() {
    if (this.unitTarget.value === 'session') {
      this.unitRelatedInputTargets().forEach(target => target.classList.add('d-none'))
    } else {
      this.unitRelatedInputTargets().forEach(target => target.classList.remove('d-none'))
    }
  }

  cellTarget(element) {
    return element.closest('.table-spreadsheet-cell')
  }

  unitRelatedInputTargets() {
    return [this.unitCounterTarget, this.thresholdTarget, this.durationTarget]
  }

}