import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["file_field", "submit_field"]
  connect() {
  }

  load_csv(e) {
    e.preventDefault();
    this.file_fieldTarget.click();
  }

  submit_form() {
    this.submit_fieldTarget.click();
  } 
}
