import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.site_owners_select = document.querySelector('#site_owner_select')
        this.site_owners_select.addEventListener('change', (e) => {
            this.display(e.target.value)
        })
        this.uid = document.querySelector('[data-controller="site-owners-access"]').className
        if (this.site_owners_select.value) {
            this.display(this.site_owners_select.value)
        }
    }

    display(id) {
        fetch(`/charge_network/site_owners/${id}/${this.uid}/site_cards`, { 
            headers: {'Accept': 'text/vnd.turbo-stream.html'}
        }).then(response => response.text())
        .then(html => {
            Turbo.renderStreamMessage(html);
        })
        .catch(error => console.error('Error fetching data:', error));
        const soCards = document.querySelector('#so_cards');
        soCards.innerHTML = '<div class="loader">Récupération des autorisations...</div>';
        let dots = 0;
        this.loaderInterval = setInterval(() => {
            dots = (dots + 1) % 4;
            soCards.innerHTML = `<div class="loader">Récupération des autorisations${'.'.repeat(dots)}</div>`;
        }, 400);
    }
}
