import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["chargePointOperators", "siteOwners"]

    connect() {
        this.owner_type = this.element.querySelector("#tariff_owner_type")
        if (!this.owner_type) {
            this.owner_type = this.element.querySelector("#tariff_day_night_owner_type");
          }
        if (!this.owner_type) {
        this.owner_type = this.element.querySelector("#tariff_basic_owner_type");
        }
          
        this.owner_type.addEventListener("change", (event) => {
            this.toggleOwnerFields(event.target.value)
        })
        this.toggleOwnerFields(this.owner_type.value)
    }

    toggleOwnerFields(ownerType) {
        if (ownerType === "ChargePointOperator") {
            this.chargePointOperatorsTarget.style.display = "grid"
            this.siteOwnersTarget.style.display = "none"
            this.siteOwnersTarget.querySelector('select').disabled = true;
            this.chargePointOperatorsTarget.querySelector('select').disabled = false;
        } else {
            this.chargePointOperatorsTarget.style.display = "none"
            this.siteOwnersTarget.style.display = "grid"
            this.siteOwnersTarget.querySelector('select').disabled = false;
            this.chargePointOperatorsTarget.querySelector('select').disabled = true;
        }
    }

}
