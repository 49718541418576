import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox']
  static values = { onClass: String, offClass: String }

  connect() {
    this.update(this.checkboxTarget)
  }

  change(event) {
   this.update(event.target)
  }

  update(checkboxTarget) {
    if (checkboxTarget.checked) {
      this.element.classList.add(this.onClassValue)
      this.element.classList.remove(this.offClassValue)
    } else {
      this.element.classList.remove(this.onClassValue)
      this.element.classList.add(this.offClassValue)
    }   
  }
}