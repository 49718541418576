import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["output", "output2"]

    update_description(event) {
        this.outputTarget
            .querySelectorAll(`[data-tariff-description-id]`)
            .forEach((element) => element.classList.add("hidden"))

        this.outputTarget
            .querySelector(`[data-tariff-description-id="${event.target.value}"]`)
            .classList.remove("hidden")
    }

}