import { Controller } from "@hotwired/stimulus"
import ibanFile from './stripe/stripe_iban_controller'
import cardFile from './stripe/stripe_card_controller'

export default class extends Controller {
    static targets = ["output", "submit", "pk", "backLink"]

    connect() {
        var stripePrivateKey = this.pkTarget.dataset.value
        var stripe = Stripe(stripePrivateKey);

        if (window.location.href.indexOf("company") > -1) {
            ibanFile(stripe);
        } else {
            cardFile(stripe);
        }
    }
}