import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['list', 'template', 'title']

  connect() {
    this.refreshTitles()
  }

  add(event) {  
    event.stopPropagation() & event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.listTarget.insertAdjacentHTML('beforeend', content)    
    this.refreshTitles()
  }

  remove(event) {
    event.stopPropagation() & event.preventDefault()
    const confirmMessage = event.currentTarget.dataset.confirm

    if (!confirmMessage || confirm(confirmMessage)) {
      let item = event.target.closest('[data-nested-form-item]')
      let itemId = item.querySelector("input[name*='id']")

      if (itemId.value.length > 0) {
        item.querySelector("input[name*='_destroy']").value = 1
        item.style.display = 'none'
      } else {
        item.remove()
      }
      this.refreshTitles()
    }
  }

  refreshTitles() {
    let index = 1
    this.titleTargets.forEach(element => {
      let item = element.closest('[data-nested-form-item]')
      if (item.style.display === 'none') return;

      let template = element.dataset.templateTitle
      if (!template) {
        template = element.dataset.templateTitle = element.innerHTML        
      }
      element.innerHTML = template.replace(/INDEX/g, index++)
    })
  }
}
