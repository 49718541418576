import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import 'bootstrap-timepicker'

export default class extends Controller {

  connect() {
    $(this.element).timepicker({
      showMeridian: false,
      maxHours: 24,
      icons: {
        up: 'ri-arrow-up-line',
        down: 'ri-arrow-down-line'
      }      
    })
  }
}