import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  connect() {
  }

  reloadPage() {
    window.location.reload()
  };
}
