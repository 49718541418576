import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "startTime", "endTime", "startTimeSpan", "endTimeSpan", "startTimeSpan2", "endTimeSpan2" ]

  update() {
    const startTimeValue = this.startTimeTarget.value;
    const endTimeValue = this.endTimeTarget.value;

    if (startTimeValue !== "") {
      this.startTimeSpanTarget.textContent = this.startTimeTarget.value;
      this.startTimeSpan2Target.textContent = this.startTimeTarget.value;
    }
    if (endTimeValue !== "") {
      this.endTimeSpanTarget.textContent = this.endTimeTarget.value;
      this.endTimeSpan2Target.textContent = this.endTimeTarget.value;
    }
  }
}
