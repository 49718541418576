import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addForm", "removeForm"]
  connect() {
    this.addFormTarget.addEventListener('ajax:success', (event, data, status, xhr) => {
      this.addFormTarget.classList.toggle('hidden');
      this.removeFormTarget.classList.toggle('hidden');
    });
    this.addFormTarget.addEventListener('ajax:error', (event, data, status, xhr) => {
      window.alert("Erreur lors de l'ajout au favoris");
    });
    this.removeFormTarget.addEventListener('ajax:success', (event, data, status, xhr) => {
      this.addFormTarget.classList.toggle('hidden');
      this.removeFormTarget.classList.toggle('hidden');
    });
    this.removeFormTarget.addEventListener('ajax:error', (event, data, status, xhr) => {
      window.alert("Erreur lors de la suppression du favoris");
    });
  }
}