import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["maintenance", "siteOwnerOptions", "select"]


    connect() {
        this.toggleUserIdField();
    }

    toggleUserIdField() {
        if (this.maintenanceTarget.checked) {
            const currentChargePointOperatorId = this.data.get("operatorId")
            const currentChargePointOperatorName = this.data.get("operatorName")
            this.selectTarget.innerHTML = `<option value="${currentChargePointOperatorId}" selected>${currentChargePointOperatorName}</option>`
        } else {
            this.selectTarget.innerHTML = this.siteOwnerOptionsTarget.innerHTML;
        }
    }
}