import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.cpo_select = document.getElementById('charge_point_operator_select');
    this.maintenance_select = document.getElementById('maintenance_select');
    this.matches = JSON.parse(document.querySelector('[data-controller="site-owner-maintenance"]').dataset.info)
    this.current_contract_id = document.querySelector('[data-controller="site-owner-maintenance"]').dataset.edit
    this.cpo_select.addEventListener('change', (e) => {
      this.change_maintenance_select(e);
    });
    this.change_maintenance_select({target: this.cpo_select})
  }

  change_maintenance_select = (e) => {
    const new_options = this.matches
    .filter((item) => {
      return item[2] == e.target.options[e.target.selectedIndex].text
    })
    .map((item) => {
      return [item[0], item[1]]
    })
    this.maintenance_select.innerHTML = "<option value='' label=' '></option>"
    new_options.forEach((item) => {
      if (this.current_contract_id == item[1]) {
        this.maintenance_select.innerHTML += `<option value="${item[1]}" selected>${item[0]}</option>`
      } else {
      this.maintenance_select.innerHTML += `<option value="${item[1]}">${item[0]}</option>`
      }
    })
  }

}
