import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event){
    let id = event.item.dataset.id
    let data = new FormData()
    let position = event.newIndex + 1
    data.append('position', position)
    let tariffId = this.data.get('tariff-id')
    Rails.ajax({
      url: this.data.get('url').replace(':id', id).replace(':tariff_id', tariffId),
      type: 'PATCH',
      data: data
    })
  }
}
