import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect = () => {
    this.so_fields = this.element.querySelector(".so-fields")
    this.type_select = this.element.querySelector("#account_type")
    this.memberable_select = this.element.querySelector("#account_memberable_type")
    this.so_select = this.element.querySelector("#account_site_owner_id")
    this.site_select = this.element.querySelector("#site-select")
    this.isEditMode = document.querySelector("form.edit_account") !== null
    
    if (!this.isEditMode) {
      if(this.memberable_select.value === "SiteOwner") {
        this.showSoFields()
      } else {
        this.hideSoFields()
        this.fillCpoRole()
      }

      this.memberable_select.addEventListener("change", (event) => {
        let choice = event.target.value
        if (choice === "SiteOwner") {
          this.showSoFields()
        } else {
          this.hideSoFields()
        }
      })

      this.so_select.addEventListener("change", (event) => {
        if(this.type_select.value === "site_owner") {
          let choice = event.target.value
          this.fetch_so_sites(choice)
        }
      })

      this.type_select.addEventListener("change", (event) => {
        if(event.target.value === "site_owner") {
          this.fetch_so_sites(this.so_select.value)
        } else {
          this.site_select.innerHTML = ""
        }
      })
    } else  {
      this.account_sites = this.element.querySelector("#account_sites").value.split("###")
      if (this.type_select.value === "Gestionnaire de Site") {
        this.fetch_so_sites(this.so_select.value)
      }
    }
  }

  fillCpoRole = () => {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "role_id";
    hiddenField.value = "charge_point_operator_admin";
    this.element.appendChild(hiddenField);
  }

  fetch_so_sites = (choice) => {
    fetch(`/charge_network/site_owners/${choice}/sites.json`)
      .then(response => response.json())
      .then(data => {
        this.generate_site_options(data)
      })
  }

  generate_site_options = (data) => {
    this.site_select.innerHTML = "";
    data.sites.forEach(site => {
      if (!this.site_select.querySelector(".checkbox-list-label")) {
        const topLabel = document.createElement("div");
        topLabel.className = "text-sm font-medium text-gray-700";
        topLabel.classList.add("checkbox-list-label");
        topLabel.textContent = "Accès";
        topLabel.style.marginBottom = "10px";
        this.site_select.appendChild(topLabel);
      }

      const container = document.createElement("div");
      container.style.display = "flex";
      container.style.marginBottom = "10px";

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.id = `site_${site[0].id}`;
      checkbox.name = "sites[]";
      checkbox.value = site[0].id;
      checkbox.style.marginRight = "10px";
      checkbox.style.marginTop = "2px";
      if (this.account_sites && this.account_sites.includes(site[0].name)) {
        checkbox.checked = true;
      }

      const label = document.createElement("label");
      label.htmlFor = `site_${site[0].id}`;
      label.textContent = site[0].name;

      container.appendChild(checkbox);
      container.appendChild(label);
      this.site_select.appendChild(container);
    });
  }

  showSoFields = () => {
    this.so_fields.style.display = "block"
  }

  hideSoFields = () => {
    this.so_fields.style.display = "none"
  }
}
