const ibanFile = (stripe) => {
  var style = {
    base: {
      color: '#32325d',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      ':-webkit-autofill': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
      ':-webkit-autofill': {
        color: '#fa755a'
      }
    }
  };

  var iban_options = {
    style: style,
    supportedCountries: ['SEPA'],
    placeholderCountry: 'FR'
  };
  var elements = stripe.elements();
  var iban = elements.create('iban', iban_options);
  var ibanElement = document.getElementById('iban-element')
  iban.mount('#iban-element');

  var form = document.getElementById('setup-form');
  var accountholderName = document.getElementById('accountholder-name');
  var email = document.getElementById('email');
  var submitButton = document.getElementById('submit-button');
  var clientSecret = submitButton.dataset.secret;

  form.addEventListener('submit', function (event) {
    event.preventDefault();
    document.getElementById('submit-button').disabled = true
    stripe.confirmSepaDebitSetup(
      clientSecret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: accountholderName.value,
          email: email.value,
        },
      },
    },
    ).then(function (result) {
      if (result.error) {
        document.getElementById('submit-button').disabled = false
        alert(result.error.message);
      } else {
        location.replace(window.location.protocol +
          '//' +
          window.location.host +
          '/company/payment_methods');
      };
    });
  });

  iban.on('change', function (event) {
    var displayError = document.getElementById('error-message');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });
}
export default ibanFile;