import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["password", "output"]

    hide() {
        if (this.passwordTarget.getAttribute('type') === "password") {
            this.passwordTarget.setAttribute('type', "text");
        } else {
            this.passwordTarget.setAttribute('type', "password");
        }
    }
}
