import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output", "submit", "pk", "backLink"];

  connect() {
    var stripePrivateKey = this.pkTarget.dataset.value;
    var stripe = Stripe(stripePrivateKey);

    var elements = stripe.elements();
    var cardElement = elements.create("card", {
      style: {
        base: {
          color: "#000",
          fontWeight: 600,
          fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",

          ":focus": {
            color: "#424770",
          },

          "::placeholder": {
            color: "#9BACC8",
          },

          ":focus::placeholder": {
            color: "#CFD7DF",
          },
        },
        invalid: {
          color: "#fff",
          ":focus": {
            color: "#FA755A",
          },
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      },
    });
    cardElement.mount("#card-element");
    var cardholderName = document.getElementById("cardholder-name");
    var setupForm = document.getElementById("setup-form");
    var clientSecret = setupForm.dataset.secret;

    setupForm.addEventListener("submit", function (ev) {
      ev.preventDefault();
      document.getElementById("card-button").disabled = true;
      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        })
        .then(function (result) {
          if (result.error) {
            document.getElementById("card-button").disabled = false;
            alert(result.error.message);
          } else {
            location.replace(
              window.location.protocol +
                "//" +
                window.location.host +
                "/mooncard/customers/new"
            );
          }
        });
    });
  }
}
