import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['aside', 'overlay']

  open() {
    this.element.classList.add('kt-app__aside--on');
    this.asideTarget.classList.add('kt-app__aside--on');
    this.overlayTarget.classList.remove('d-none');
  }

  close() {
    this.element.classList.remove('kt-app__aside--on');
    this.asideTarget.classList.remove('kt-app__aside--on');
    this.overlayTarget.classList.add('d-none'); 
  }
}
