import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'city', 'postalCode', 'country', 'europeWarning']

  connect() {
    this.autoComplete()
  }

  autoComplete() {
    const options = {
      fields: ["address_components", "geometry", "icon", "name", "place_id"]
    };
    var autocomplete = new google.maps.places.Autocomplete(this.inputTarget, options);
    autocomplete.addListener('place_changed', placeChanged);

    function placeChanged() {
      var europeCountries = [ "AD","AL","AT","BA","BE","BG","BY","CH","CZ","DE","DK","EE","ES",
                              "FI","FO","FR","GB","GG","GI","GR","HR","HU","IE","IM","IS","IT",
                              "JE","LI","LT","LU","LV","MC","MD","ME","MK","MT","NL","NO","PL",
                              "PT","RE","RO","RS","RU","SE","SI","SJ","SK","SM","UA" ];

      var place = autocomplete.getPlace();
      var address = placeToAddress(place);

      if (!europeCountries.includes(address.Country.short_name)) {
        document.querySelector("#europeWarning").classList.remove('hidden');
        document.querySelector("#street").value = '';
        document.querySelector("#postal_code").value = '';
        document.querySelector("#city").value = '';
        document.querySelector("#country").value = '';

        return;
      }
      document.querySelector("#europeWarning").classList.add('hidden');

      if (address.StreetNumber) {
        document.querySelector("#street").value = `${address.StreetNumber.long_name} ${address.StreetName.long_name}`
      } else {
        document.querySelector("#street").value = `${address.StreetName.long_name}`
      }
      if (address.Zip) {
        document.querySelector("#postal_code").value = address.Zip.long_name;
      }
      if (address.City) {
        document.querySelector("#city").value = address.City.long_name;
      }
      document.querySelector("#country").value = address.Country.long_name;
    }

    function placeToAddress(place){
      var address = {};
      place.address_components.forEach(function(c) {
          switch(c.types[0]){
              case 'street_number':
                  address.StreetNumber = c;
                  break;
              case 'route':
                  address.StreetName = c;
                  break;
              case 'neighborhood': case 'locality':
                  address.City = c;
                  break;
              case 'administrative_area_level_1':
                  address.State = c;
                  break;
              case 'postal_code':
                  address.Zip = c;
                  break;
              case 'country':
                  address.Country = c;
                  break;
          }
      });
  
      return address;
  }
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}

