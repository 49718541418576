import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'street', 'city', 'postalCode', 'country', 'lon', 'lat', 'map', 'details']

  static map;
  static autocomplete;
  static marker;

  connect() {
    this.inputTarget.addEventListener('keypress', (e) => { e.keyCode == '13' ? e.preventDefault() : null; }, false);
    this.lonTarget.addEventListener('keypress', (e) => { e.keyCode == '13' ? e.preventDefault() : null; }, false);
    this.latTarget.addEventListener('keypress', (e) => { e.keyCode == '13' ? e.preventDefault() : null; }, false);

    this.lonTarget.addEventListener('change', (e) => {this.updatedLonLat();});
    this.latTarget.addEventListener('change', (e) => {this.updatedLonLat();});
    this.initMap();
    this.initSearchBar();
  }

  updatedLonLat() {
    this.placeMarker(this.lonTarget.value, this.latTarget.value);
    this.map.setCenter(new google.maps.LatLng(parseFloat(this.latTarget.value), parseFloat(this.lonTarget.value)));
    this.map.setZoom(15);
  }

  updatedTextFields() {
    console.log(search_text);
  }

  placeMarker(lon, lat) {
    if (this.marker) {
      this.marker.setMap(null);
    }
    this.marker = new google.maps.Marker({
      map: this.map,
      position: { lat: parseFloat(lat), lng: parseFloat(lon) }
    });
  }

  initMap() {
    this.map = new google.maps.Map(
      this.mapTarget,
      {
        center: new google.maps.LatLng(this.latTarget.value || 46.7813476, this.lonTarget.value || 2.0556368),
        zoom: this.lonTarget.value != '' ?  15 : 6
      }
    );
    this.placeMarker(this.lonTarget.value, this.latTarget.value);
  }

  initSearchBar() {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, ["address_components", "geometry", "icon", "name", "place_id"]);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();
    if (place.geometry === undefined) return;
    this.map.setCenter(place.geometry.location);
    this.map.setZoom(15);
    const lng = this.map.getCenter().lng();
    const lat = this.map.getCenter().lat();
    this.lonTarget.value = lng;
    this.latTarget.value = lat;
    this.placeMarker(lng, lat);
    
    let route = place.address_components.find(function (component) {
      return component.types.includes("route");
    }).long_name;

    let number = place.address_components.find(function (component) {
      return component.types.includes("street_number");
    })?.long_name;

    if( number == undefined ){
      number = '';
    }

    this.streetTarget.value = number + ' ' + route;

    this.postalCodeTarget.value = place.address_components.find(function (component) {
      return component.types.includes("postal_code");
    }).long_name;

    this.cityTarget.value = place.address_components.find(function (component) {
      return component.types.includes("locality");
    }).long_name;

    this.countryTarget.value = place.address_components.find(function (component) {
      return component.types.includes("country");
    }).long_name;
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }  
}