// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "kind", "pricingRule", "coefficient" ]

  connect() {
    this.kindTarget.querySelector('select').addEventListener('change', function(ev) {
      ev.preventDefault();
      this.togglePricingCategoryHiddenFields();
    }.bind(this), false);
    this.togglePricingCategoryHiddenFields();
  }

  togglePricingCategoryHiddenFields() {
    if (this.kindTarget.querySelector('select').value == 'rule') {
      this.coefficientTarget.hidden = true;
      this.pricingRuleTarget.hidden = false;
    } else {
      this.coefficientTarget.hidden = false;
      this.pricingRuleTarget.hidden = true;    
    }
  }
}
