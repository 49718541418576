import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["countryCode", "partyId", "publicCheckbox", "result", "hiddenField"]

  updateConcatenation() {
    const countryCode = this.countryCodeTarget.value;
    const partyId = this.partyIdTarget.value;
    const isPublic = this.publicCheckboxTarget.checked;
    let result = `${countryCode}*${partyId}`;

    if (!isPublic) {
      result += '*P';
    }

    this.resultTarget.value = result.toUpperCase();
    this.hiddenFieldTarget.value = result.toUpperCase();

  }

  connect() {
    if (this.hiddenFieldTarget.value !== "") {
      this.resultTarget.value = this.hiddenFieldTarget.value;
    }
  }
}